import Spinner from "react-bootstrap/Spinner";

export const Loader = () => {
  return (
    <div className="d-flex align-items-center justify-content-center w-100">
      <Spinner animation="border" />
      <span className="sr-only">Loading...</span>
    </div>
  );
};
