import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AuthState } from "types";
import { RootState } from "../store";

// Selectors
export const selectors = {
  getAuth: (state: RootState) => state.auth,
};

// Slice
export const initialState: AuthState = {
  token: "",
  valid: false,
  validationError: "",
};

export const { actions, reducer } = createSlice({
  name: "auth",
  initialState,
  reducers: {
    update: (state: AuthState, action: PayloadAction<AuthState>): AuthState => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});
