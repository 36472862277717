import {
  combineReducers,
  configureStore,
  // getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage/session";

import migrations from "./migrations";
import { reducer as addressReducer } from "./address";
import { reducer as errorReducer } from "./error";
import { reducer as modalReducer } from "./modal";
import { reducer as orderReducer } from "./order";
import { reducer as authReducer } from "./auth";

export const rootReducer = combineReducers({
  address: addressReducer,
  error: errorReducer,
  modal: modalReducer,
  order: orderReducer,
  auth: authReducer,
});

const persistConfig = {
  blacklist: ["error", "modal"],
  key: "root",
  migrate: createMigrate(migrations, { debug: false }),
  storage,
  version: 0,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const middleware = getDefaultMiddleware({
//   serializableCheck: {
//     ignoredActions: ["persist/PERSIST"],
//   },
// });

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }),
  devTools: true,
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
