import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { Layout } from "../Layout";
import { Loader } from "components/Loader";
import * as routes from "routes";

const AssetsPage = React.lazy(() => import("pages/AssetsPage"));
const CheckoutPage = React.lazy(() => import("pages/CheckoutPage"));
const ConfirmationPage = React.lazy(() => import("pages/ConfirmationPage"));
const EquipmentPage = React.lazy(() => import("pages/EquipmentPage"));
const HomePage = React.lazy(() => import("pages/HomePage"));
const LocationPage = React.lazy(() => import("pages/LocationPage"));
const ReturnAddressPage = React.lazy(() => import("pages/ReturnAddressPage"));

const BASENAME = "/kits";

export const Router = () => {
  return (
    <BrowserRouter basename={BASENAME}>
      <React.Suspense fallback={<Loader />}>
        <Layout>
          <Switch>
            <Route exact path={routes.assets}>
              <AssetsPage />
            </Route>
            <Route exact path={routes.checkout}>
              <CheckoutPage />
            </Route>
            <Route exact path={routes.confirm}>
              <ConfirmationPage />
            </Route>
            <Route exact path={routes.equipment}>
              <EquipmentPage />
            </Route>
            <Route exact path={routes.home}>
              <HomePage />
            </Route>
            <Route exact path={routes.location}>
              <LocationPage />
            </Route>
            <Route exact path={routes.returnAddress}>
              <ReturnAddressPage />
            </Route>
          </Switch>
        </Layout>
      </React.Suspense>
    </BrowserRouter>
  );
};
