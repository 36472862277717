import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as destinationApi from "api/destination";
import { actions as errorActions } from "../error";
import { regions } from "utils/regions";
import { ReturnAddress } from "types";
import { RootState } from "../store";

interface AddressState {
  addresses: {
    [region: string]: ReturnAddress[];
  };
  newAddresses: {
    [region: string]: ReturnAddress[];
  };
  nextDestinationId: number;
}

export const selectors = {
  getAddresses: (state: RootState) => state.address.addresses,
  getNewAddresses: (state: RootState) => state.address.newAddresses,
  getNextDestinationId: (state: RootState) => state.address.nextDestinationId,
};

// Slice
export const initialState: AddressState = {
  addresses: regions.reduce<Record<string, ReturnAddress[]>>((map, region) => {
    map[region.abbreviation] = [];
    return map;
  }, {}),
  newAddresses: regions.reduce<Record<string, ReturnAddress[]>>(
    (map, region) => {
      map[region.abbreviation] = [];
      return map;
    },
    {}
  ),
  nextDestinationId: 1,
};

export const loadDestinationsForRegion = createAsyncThunk(
  "address/loadDestinationsForRegion",
  async (region: string, { dispatch }) => {
    let addresses: ReturnAddress[] = [];

    try {
      addresses = await destinationApi.loadDestinationsForRegion(region);
    } catch (error) {
      const message = error instanceof Error ? error.message : String(error)
      dispatch(errorActions.setError(message));
    } finally {
      return addresses;
    }
  }
);

export const { actions, reducer } = createSlice({
  name: "address",
  initialState,
  reducers: {
    addAddress: (state: AddressState, action: PayloadAction<ReturnAddress>) => {
      return {
        ...state,
        newAddresses: {
          ...state.newAddresses,
          [action.payload.state]: [
            action.payload,
            ...state.newAddresses[action.payload.state],
          ],
        },
      };
    },
    setNextDestinationId: (
      state: AddressState,
      action: PayloadAction<number>
    ) => {
      return {
        ...state,
        nextDestinationId: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadDestinationsForRegion.fulfilled, (state, action) => {
      let nextDestinationId = state.nextDestinationId;

      action.payload.forEach((address) => {
        if (nextDestinationId < Number(address.destination_id)) {
          nextDestinationId = Number(address.destination_id) + 1;
        }
      });

      return {
        ...state,
        addresses: {
          ...state.addresses,
          [action.meta.arg]: action.payload,
        },
        nextDestinationId,
      };
    });
  },
});
