import * as api from "..";
import * as endpoints from "../endpoints";
import { Order } from "types";
import { OrderAdapter } from "adapters/OrderAdapter";

export const addJob = async (order: Order, token: string) => {
  const { data } = await api.post<{ job_id: string }>(endpoints.job, {
    action: "add_label_job",
    ...OrderAdapter(order),
    token,
  });

  return data.job_id;
};
