import axios from "axios";

const username = import.meta.env.VITE_API_KEY || "";
export const BASIC_AUTH = {
  auth: { username, password: "" },
};

export const http = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

const get = (endpoint: string, params: any) =>
  http.get(endpoint, { params, ...BASIC_AUTH });
const post = <ResponseType>(endpoint: string, params: any) =>
  http.post<ResponseType>(endpoint, params, BASIC_AUTH);

export { get, post };
