import * as api from "..";
import * as endpoints from "../endpoints";
import { ReturnAddress } from "types";

type DestinationResponse = {
  destination: ReturnAddress[]
  error?: string
  result: 'Success' | 'Failure'
}

export const loadDestinationsForRegion = async (region: string) => {
  const { data } = await api.post<DestinationResponse>(endpoints.destination, {
    action: "list",
    brand_id: 25,
    owner_company: 180480,
    state: region,
  });

  return data.destination;
};
