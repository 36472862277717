import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

interface ErrorState {
  error: string;
}

export const selectors = {
  getError: (state: RootState) => state.error.error
};

export const initialState: ErrorState = {
  error: ""
};

export const { actions, reducer } = createSlice({
  name: "error",
  initialState,
  reducers: {
    clearError: (state: ErrorState, _action: PayloadAction<void>) => {
      return {
        ...state,
        error: ""
      };
    },
    setError: (state: ErrorState, action: PayloadAction<string>) => {
      return {
        ...state,
        error: action.payload
      };
    }
  }
});
